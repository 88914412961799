import React from 'react';

interface Props extends React.PropsWithChildren {
  on: boolean | string | number;
}

/*
    Simple visibility component
    Usage:
        <Show on={true} >Hello world!</Show>
*/
export const Show = (props: Props) => {
  return !props.on ? null : <>{props.children}</>;
};
