import { loggedInUser, useOrgService } from 'hooks';
import { useQuery } from '@tanstack/react-query';

export const useDepartmentsCache = () => {
  const orgService = useOrgService();
  const { isLoggedIn } = loggedInUser.useController();

  const departmentsQuery = useQuery({
    queryKey: ['departments'],
    queryFn: () => orgService.getSollisDepartments(),
    staleTime: Infinity,
    enabled: isLoggedIn
  });

  const { isLoading: isDepartmentsLoading, data: departments = [] } = departmentsQuery;

  return {
    isDepartmentsLoading,
    departments
  };
};
