import { LabResultFileName } from 'services';
import { ITestResult } from 'types';

/*
    Standardizes the query keys (cache keys)!  Objective is to provide functions that build query keys based on params.
*/

export const useQueryKeys = () => {
  return {
    testResultDetails: (testResultId: ITestResult['labResultId']) => ['testResultDetails', testResultId],
    testResultDetailsFiles: (testResultId: ITestResult['labResultId']) => ['testResultDetails', testResultId, 'files'],
    testResultDetailsFile: (testResultId: ITestResult['labResultId'], fileName: LabResultFileName) => [
      'testResultDetails',
      testResultId,
      'files',
      fileName
    ]
  };
};
