import Icon from '@mui/icons-material/TravelExplore';
import { IMedicalPartner } from 'types';
import IconButton from '@mui/material/IconButton';

interface Props {
  record: IMedicalPartner;
}

export const WebsiteIcon = ({ record }: Props) => {
  return !record?.website ? null : (
    <IconButton onClick={() => window.open(record.website, '_blank')}>
      <Icon />
    </IconButton>
  );
};
