import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useMedicalPartnersSearch } from '../hooks/use-medical-partners-search';
import { PartnerListItem } from './partner-list-item';
import CircularProgress from '@mui/material/CircularProgress';
import { Show } from 'components/Show';
import { Box } from '@mui/material';
import { useSearchMode } from './search-mode-provider';
import { PartnersListPagination } from './partners-list-pagination';

export const PartnersList = () => {
  const { records, isLoading, noneFound } = useMedicalPartnersSearch();
  const { isBrowsingResults } = useSearchMode();
  return !isBrowsingResults ? null : (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Name</TableCell>
              <TableCell>Account</TableCell>
              <TableCell align="center">Specialty</TableCell>
              <TableCell align="center">Insurance</TableCell>
              <TableCell align="center">Notes</TableCell>
              <TableCell align="center">Website</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {records.map((record, key) => (
              <PartnerListItem key={key} record={record} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Show on={!noneFound && !isLoading && records.length}>
        <PartnersListPagination />
      </Show>
      <Show on={noneFound}>
        <Box sx={{ display: 'flex', justifyContent: 'center', padding: '20px' }}>No Medical Partners Found.</Box>
      </Show>
      <Show on={isLoading}>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '100%', padding: '20px' }}>
          <CircularProgress size="30px" />
        </Box>
      </Show>
    </>
  );
};
