import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Icon from '@mui/icons-material/Search';
import { useSearchMode } from './search-mode-provider';

export const SearchIcon = () => {
  const { isFormulatingSearch, formulateSearch } = useSearchMode();
  return isFormulatingSearch ? null : (
    <Stack direction="row" sx={{ justifyContent: 'flex-end', paddingRight: '10px', paddingBottom: '10px' }}>
      <IconButton onClick={() => formulateSearch()}>
        <Icon />
      </IconButton>
    </Stack>
  );
};
