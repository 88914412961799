import { useSearchFilters } from './search-filters-provider';
import React from 'react';
import { TextFieldObjectSelector } from '../../../components/TextFieldObjectSelector';
import { first } from 'lodash';
import { FormLabel } from '@mui/material';
import { SearchFilterField } from '../definitions';

interface Props {
  label: string;
  field: SearchFilterField;
  wrapper?: React.FC<React.PropsWithChildren>;
}

export const SelectorInput = ({ label, field, wrapper: Wrapper = ({ children }: React.PropsWithChildren) => <>{children}</> }: Props) => {
  const { getValue, setValue, getOptions, getOptionLabel } = useSearchFilters();
  const options = React.useMemo(() => [{ label: ' ', value: '' }, ...getOptions(field)], [getOptions]);
  const option = first(options.filter((o) => o.value === getValue(field)));
  return (
    <Wrapper>
      <FormLabel>{label}</FormLabel>
      <TextFieldObjectSelector
        options={options}
        onGetLabel={getOptionLabel}
        value={option}
        onChange={(o) => setValue(field, o?.value)}
        fullWidth={true}
      />
    </Wrapper>
  );
};
