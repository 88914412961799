import Pagination from '@mui/material/Pagination';
import { Box } from '@mui/system';
import { useMedicalPartnersSearch } from '../hooks/use-medical-partners-search';
import { useSearchFilters } from './search-filters-provider';
import { SearchFilterField } from '../definitions';

export const PartnersListPagination = () => {
  const { pageIncrement = 0, pageSize = 1, totalCount = 0 } = useMedicalPartnersSearch();
  const { setValue } = useSearchFilters();
  const pages = Math.ceil(totalCount / pageSize);
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '100%', padding: '20px' }}>
      <Pagination page={pageIncrement + 1} count={pages} onChange={(_e, page) => setValue(SearchFilterField.pageIncrement, page - 1)} />
    </Box>
  );
};
