import { createSlice } from '@reduxjs/toolkit';
import { ITestResultsState } from 'types';

const initialState: ITestResultsState = {
  isLoadingDetails: false,
  details: []
};

const slice = createSlice({
  name: 'appointments',
  initialState,
  reducers: {
    isLoadingDetails(state, action) {
      return {
        ...state,
        isLoadingDetails: action.payload
      };
    },
    loadDetails(state, action) {
      return {
        ...state,
        isLoadingDetails: false,
        details: action.payload
      };
    }
  }
});

export default slice.reducer;
export const { isLoadingDetails, loadDetails } = slice.actions;
