import { ITaskConversation, ITaskConversationType } from 'types';
import ApiService from './api-service';

export class ConversationService {
  protected apiService: ApiService;

  constructor(apiService: ApiService) {
    this.apiService = apiService;
  }

  getConversationTypes(): Promise<ITaskConversationType[]> {
    return this.apiService.get(`tasks/v1/Lookup/conversationTypes`).then((r) => r?.data);
  }

  getTaskConversations(taskId: string): Promise<ITaskConversation[]> {
    return this.apiService.get(`tasks/v1/Conversation/${taskId}/conversations`).then((r) => r?.data);
  }

  createConversation(taskConversation: ITaskConversation): Promise<ITaskConversation> {
    return this.apiService.post(`tasks/v1/Conversation`, taskConversation).then((r) => r?.data);
  }

  updateConversationIdentifier(taskConversation: ITaskConversation): Promise<void> {
    return this.apiService.put(`tasks/v1/Conversation/updateIdentifier`, taskConversation).then(() => {});
  }

  handleResponse(taskConversation: ITaskConversation): Promise<void> {
    return this.apiService.put(`tasks/v1/Conversation/outboundResponse`, taskConversation).then(() => {});
  }

  deleteTaskConversation(taskConversation: ITaskConversation): Promise<void> {
    return this.apiService.put(`tasks/v1/Conversation/removeTaskConversation`, taskConversation).then(() => {});
  }
}

export default ConversationService;
