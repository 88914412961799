export enum taskTypeEnum {
  Feedback = 1,
  MedicalBilling = 2,
  AppointmentReview = 3,
  LabMessage = 4,
  Imaging = 5,
  AppointmentRequest = 6,
  PreOpProviderScreen = 7,
  WelcomeConsultation = 8,
  MedicalIssue = 9,
  Aftercare = 10,
  AftercareStatLabSignout = 11,
  AftercareUrgentSignout = 12,
  Other = 13,
  SpecialistReferral = 14,
  BillingHoldOnCharging = 15,
  NewMembership = 16,
  MembershipLifeEvent = 17,
  PreventativeImaging = 18,
  MedicalRecordsRequest = 19,
  HouseCallRequest = 20,
  RefillRequest = 21,
  EUCovidCertificateRequest = 22,
  HospitalAdvocacy = 23,
  HouseCallLabOrders = 24,
  ImagingPARequest = 25,
  LetterOfCovidRecovery = 26,
  STATReferral = 27,
  ProviderTriage = 28,
  RequestOutsideMedicalRecords = 29,
  STATSpecialistReferral = 30,
  TravelInquiry = 31,
  VaccineRequest = 32,
  Reminder = 33,
  ProviderReferralScreening = 34,
  OutpatientImagingRequest = 35,
  HomeImagingOrder = 36,
  AftercareFollowUp = 37,
  AppointmentScheduled = 38,
  MembershipInquiry = 39,
  MemberPortalSupport = 40,
  AftercareAutomaticResponse = 41,
  Renewels = 42,
  NewMemberOnboarding = 43,
  FeedbackServiceRecovery = 44,
  Referral = 45,
  FormsRequest = 46,
  TAFRequest = 47
}

export enum taskStatusEnum {
  New = 1,
  InProcess = 2,
  InProgress = 3,
  OnHold = 4,
  Blocked = 5,
  Closed = 6,
  Resolved = 7
}

export enum taskPriorityEnum {
  Routine = 1,
  High = 2,
  Emergency = 3
}

export enum taskClosedReasonEnum {
  CancelledRequest = 1,
  Duplicate = 2,
  NonIssue = 3,
  Resolved = 4,
  Spam = 5,
  HangUp = 6
}

export enum taskSourceEnum {
  Email = 'Email',
  MemberPortal = 'MemberPortal',
  Navigator = 'Navigator',
  Talkdesk = 'Talkdesk',
  SMS = 'SMS'
}

export enum taskArchiveTimingEnum {
  Now = 1,
  OneHour = 2,
  ThreeDays = 3
}
