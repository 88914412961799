import { useEnvironment, Environment } from 'hooks';
import React from 'react';
import { SetFeatureEnvironment, FeatureEnvironment, useFeatures } from 'sollishealth.core.react';

const environmentsMap = {
  [Environment.DEV]: FeatureEnvironment.DEV,
  [Environment.STAGE]: FeatureEnvironment.STAGE,
  [Environment.PRODUCTION]: FeatureEnvironment.PRODUCTION
};

const useFeaturesLoader = () => {
  const { environment, features } = useEnvironment();
  const featureEnvironment = React.useMemo(() => environmentsMap[environment], [environment]);
  const { setFeature } = useFeatures();
  //  Create features from environment variables
  React.useEffect(() => {
    Object.keys(features || {}).map((key) => {
      setFeature(
        key,
        //  Create an IFeature from the known environments mappings
        Object.values(environmentsMap).reduce(
          (feature, e) => ({
            ...feature,
            [e]: Boolean(features[key])
          }),
          {}
        )
      );
    });

    //  Show what we have available to the console
    console.groupCollapsed('Features');
    Object.keys(features || {}).map((key) => console.info(`${key}:`, features[key]));
    console.groupEnd();
  }, [features]);

  return {
    featureEnvironment
  };
};

export const FeaturesLoader = () => {
  const { featureEnvironment } = useFeaturesLoader();
  return (
    <>
      <SetFeatureEnvironment featureEnvironment={featureEnvironment} />
    </>
  );
};
