import useApiDataService from 'hooks/services/use-api-data-service';
import { ApiService } from 'services';
import { IMember, IReferral } from 'types';

export class EncountersService {
  protected apiService: ApiService;

  constructor(apiService: ApiService) {
    this.apiService = apiService;
  }

  getMemberReferrals(memberId: IMember['memberId']): Promise<IReferral[]> {
    return this.apiService.get(`Encounters/v1/Referral/member/${memberId}`).then((r) => r?.data);
  }
}

export const useEncounterService = () => useApiDataService<EncountersService>(EncountersService);
