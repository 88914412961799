// @ts-nocheck
import { ReactNode } from 'react';
import ShowMoreText from 'react-show-more-text';

interface Props {
  textContent?: string | ReactNode;
  showLines?: number;
  expanded?: boolean;
}

export const ShowMoreTextContent = (props: Props) => {
  const { textContent, showLines = 3, expanded = false } = props;
  return (
    // @ts-ignore: Cannot fix build error because it is caused by a 3rd party library
    <ShowMoreText lines={showLines} anchorClass="text-show-more" expanded={expanded}>
      {/* @ts-ignore: Cannot fix build error because it is caused by a 3rd party library */}
      {textContent}
    </ShowMoreText>
  );
};

export default ShowMoreTextContent;
