import { useMemberService } from 'hooks';
import { useSearchFilters } from '../components/search-filters-provider';
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { IMedicalPartnerSearchParams } from 'services';
import { useSearchMode } from '../components/search-mode-provider';
import { MILLISECONDS } from 'types';

export const useMedicalPartnersSearch = () => {
  const { filters, hasFilters } = useSearchFilters();
  const { isBrowsingResults } = useSearchMode();
  const memberService = useMemberService();
  const queryParams = React.useMemo(() => {
    const queryKey = ['medPartnerSearch', JSON.stringify(filters)];
    const searchParams: IMedicalPartnerSearchParams = {
      searchName: filters.name,
      searchSpecialty: filters.speciality,
      searchCity: filters.city,
      searchState: filters.state,
      searchCountry: filters.country,
      searchAffiliation: filters.affiliation,
      insuranceStatus: filters.insurance,
      hamptonsCoverage: filters.hamptons,
      locations: filters.locations,
      pageIncrement: filters.pageIncrement || 0
    };
    const enabled = isBrowsingResults;
    return {
      queryKey,
      queryFn: () => memberService.searchMedPartners(searchParams),
      enabled,
      staleTime: MILLISECONDS.DAY
    };
  }, [filters, isBrowsingResults]);

  const searchQuery = useQuery(queryParams);
  const { data, isLoading } = searchQuery;
  const { records = [], pageIncrement, pageSize, recordsCount, totalCount } = data || {};
  const noneFound = hasFilters && !records.length && !isLoading;
  return {
    records,
    isLoading,
    noneFound,
    pageIncrement,
    pageSize,
    recordsCount,
    totalCount
  };
};
