import { DrawerButtonProps } from 'components/DrawerButton';
import React from 'react';
import LocalPharmacy from '@mui/icons-material/LocalPharmacy';
import { useNavigate } from 'react-router-dom';
import { routeUrl } from '../constants/route-url';

interface Props {
  drawerButtonComponent: React.FC<DrawerButtonProps>;
}

export const LeftNavLink = ({ drawerButtonComponent: DrawerButton }: Props) => {
  const navigate = useNavigate();
  const onClick = () => navigate(routeUrl);

  return <DrawerButton label="Medical Partners" iconComponent={LocalPharmacy} onClick={onClick} />;
};
