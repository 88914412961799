import { createContextForController } from 'react-controller-context';
import React from 'react';
import { get } from 'lodash';
import { useMemberService } from 'hooks';
import { SearchFilterField, ISelectorOption } from '../definitions';
import { useMedPartnerEnumerationSetter } from '../hooks/use-med-partner-enumeration-setter';

export interface ISearchFilter {
  name?: string;
  speciality?: string;
  city?: string;
  state?: string;
  country?: string;
  affiliation?: string;
  insurance?: string[];
  hamptons?: boolean;
  locations?: string[];
  pageIncrement?: number;
}

interface ISelectorOptionsEnumerators {
  [key: string]: ISelectorOption[];
}

const emptyOptions: ISelectorOption[] = [];

const useController = () => {
  const memberService = useMemberService();

  const [filters, setFilters] = React.useState<ISearchFilter>({});
  const [selectorOptions, setSelectorOptions] = React.useState<ISelectorOptionsEnumerators>({});

  const getValue = React.useCallback((key: SearchFilterField) => get(filters, key), [filters]);
  const setValue = (key: SearchFilterField, value: any) => setFilters((f) => ({ ...f, [key]: value }));

  const getOptions = React.useCallback((key: SearchFilterField) => get(selectorOptions, key, emptyOptions), [selectorOptions]);
  const setOptions = (key: SearchFilterField, value: ISelectorOption[]) => setSelectorOptions((so) => ({ ...so, [key]: value }));

  const getOptionLabel = (o: ISelectorOption) => o?.label;
  const getOptionValue = (o: ISelectorOption) => o?.value;

  //  Load up selector options with enums
  useMedPartnerEnumerationSetter(SearchFilterField.insurance, () => memberService.getMedPartnerInsuranceStatuses(), setOptions);
  useMedPartnerEnumerationSetter(SearchFilterField.city, () => memberService.getMedPartnerCities(), setOptions);
  useMedPartnerEnumerationSetter(SearchFilterField.state, () => memberService.getMedPartnerStates(), setOptions);
  useMedPartnerEnumerationSetter(SearchFilterField.country, () => memberService.getMedPartnerCountries(), setOptions);
  useMedPartnerEnumerationSetter(SearchFilterField.affiliation, () => memberService.getMedPartnerHospitalAffiliations(), setOptions);
  useMedPartnerEnumerationSetter(SearchFilterField.speciality, () => memberService.getMedPartnerSpecialties(), setOptions);
  useMedPartnerEnumerationSetter(SearchFilterField.locations, () => memberService.getMedPartnerLocations(), setOptions);

  const hasFilters = Object.values(filters).some((f) => f.length || f === true);

  return {
    getValue,
    setValue,
    getOptions,
    getOptionLabel,
    getOptionValue,
    filters,
    hasFilters
  };
};

const context = createContextForController(useController);
export const SearchFiltersProvider = context.Provider;
export const useSearchFilters = context.useController;
