import { SearchFiltersProvider } from '../components/search-filters-provider';
import { PartnersList } from '../components/partners-list';
import { Paper } from '@mui/material';
import { SearchModeProvider } from '../components/search-mode-provider';
import { composeProvider } from 'react-compose-provider';
import { TopSection } from '../components/top-section';

const Providers = composeProvider(SearchFiltersProvider, SearchModeProvider);

export const SearchPage = () => {
  return (
    <Providers>
      <Paper sx={{ padding: 2, width: '100%', backgroundColor: 'rgb(255,255,255)' }} elevation={1}>
        <TopSection />
        <PartnersList />
      </Paper>
    </Providers>
  );
};
