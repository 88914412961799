import { FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import React from 'react';

type Value = string | number;
type Label = string;

interface CheckboxListProps<Type> {
  options: Type[];
  values: Value[];
  onChange?: (v: Value[]) => void;
  //  returns label for an object
  onGetLabel: (v: Type) => Label;
  //  returns value for an object
  onGetValue: (v: Type) => Value;
}

export const CheckboxList = <Type extends object>({
  options = [],
  values = [],
  onChange = () => {},
  onGetLabel: getLabel,
  onGetValue: getValue
}: CheckboxListProps<Type>) => {
  const handleChange = React.useCallback(
    (v: Value, checked: boolean) => {
      if (checked) {
        onChange([...values, v]);
      } else {
        onChange(values.filter((value) => value !== v));
      }
    },
    [values]
  );

  return (
    <FormGroup>
      {options.map((option) => (
        <FormControlLabel
          key={`${getValue(option)}-${getLabel(option)}`}
          control={
            <Checkbox checked={values.includes(getValue(option))} onChange={(e) => handleChange(getValue(option), e.target.checked)} />
          }
          label={getLabel(option)}
        />
      ))}
    </FormGroup>
  );
};

export default CheckboxList;
