import { MenuItem, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { IRequestConfig } from 'types';
import { api } from 'utils';
import settings from 'constants/appsettings.json';
import { useDepartmentsCache } from 'hooks';

export interface LocationSelectorProps {
  onSelect: (event: any) => void;
  currentLocation: string | '' | undefined;
  label: string;
  name: string;
  helperText: string | false | undefined;
  error: boolean | undefined;
  isDisabled: boolean | undefined;
  isRequired: boolean | undefined;
}

const LocationSelector: React.VFC<LocationSelectorProps> = (props) => {
  const { onSelect, currentLocation, label, name, helperText, error, isDisabled, isRequired } = props;
  const { departments } = useDepartmentsCache();

  return (
    <TextField
      select
      required={isRequired}
      disabled={isDisabled}
      label={label}
      id={name}
      name={name}
      onChange={onSelect}
      value={currentLocation}
      helperText={helperText}
      error={error}
    >
      {departments.map((item, index: number) => {
        return (
          <MenuItem key={index} value={item.locationName}>
            {`${item.locationName} - ${item.locationState}`}
          </MenuItem>
        );
      })}
    </TextField>
  );
};

export default LocationSelector;
