import { Grid } from '@mui/material';
import { TextInput } from '../components/text-input';
import React from 'react';
import { SelectorInput } from '../components/selector-input';
import { ToggleInput } from '../components/toggle-input';
import { SearchButton } from '../components/search-button';
import { MultiSelectorInput } from '../components/multi-selector-input';
import { CheckboxesInput } from '../components/checkboxes-input';
import { SearchFilterField } from '../definitions';

const GridWrapper = ({ children }: React.PropsWithChildren) => (
  <Grid item xs={12}>
    {children}
  </Grid>
);

export const SearchForm = () => {
  return (
    <Grid container spacing={2} rowSpacing={5}>
      <Grid item xs={6}>
        <Grid container spacing={2} rowSpacing={5}>
          <TextInput label="Name" field={SearchFilterField.name} wrapper={GridWrapper} />
          <SelectorInput label="Speciality" field={SearchFilterField.speciality} wrapper={GridWrapper} />
          <SelectorInput label="City" field={SearchFilterField.city} wrapper={GridWrapper} />
          <SelectorInput label="State" field={SearchFilterField.state} wrapper={GridWrapper} />
          <SelectorInput label="Country" field={SearchFilterField.country} wrapper={GridWrapper} />
          <SelectorInput label="Hospital Affiliation" field={SearchFilterField.affiliation} wrapper={GridWrapper} />
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Grid container spacing={2} rowSpacing={5}>
          <CheckboxesInput label="Insurance Status" field={SearchFilterField.insurance} wrapper={GridWrapper} />
          <ToggleInput label="Hamptons Coverage" field={SearchFilterField.hamptons} wrapper={GridWrapper} />
          <MultiSelectorInput label="Medical Partner Location" field={SearchFilterField.locations} wrapper={GridWrapper} />
        </Grid>
      </Grid>
      <Grid item xs={6} />
      <Grid item xs={6}>
        <SearchButton wrapper={GridWrapper} />
      </Grid>
    </Grid>
  );
};
